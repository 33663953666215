<template>
  <div :class="$style.listItem" @click="handle2Detail">
    <img :class="$style.listImg" :src="info.image_url || info.ddc_image_url" />
    <p :class="$style.name">{{ info.name || info.ddc_name }}</p>
    <!-- <DdcnoCard v-if="isCollect" :no="info.ddc_no" /> -->
    <div v-if="isCollect" :class="$style.ddc_no">{{ info.ddc_no }}</div>
    <div v-else :class="$style.author">
      <img :class="$style.avatar" :src="info.headimg" />
      <span :class="$style.authorName">{{ info.artist_name }}</span>
    </div>
  </div>
</template>

<script>
// import DdcnoCard from "@/components/DdcnoCard";
export default {
  components: {
    // DdcnoCard,
  },
  props: {
    isCollect: {
      type: Boolean,
      default: false, // 是否是藏品，是的话跳转到藏品页面
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handle2Detail() {
      // 在优选列表中出现时走此逻辑
      if (!this.info.department_id) {
        this.$router.push({ path: `/cloth/detail/${this.info.id}` });
      }
      if (this.isCollect) {
        // 我的藏品，点击去藏品详情
        this.$router.push({
          path: "/personal/collect",
          query: {
            department_id: this.info.department_id,
            ddc_id: this.info.ddc_id,
          },
        });
        return;
      }
      // // 下面两个为我的藏品的跳转逻辑
      // if (this.info.department_id === 1) {
      //   this.$router.push({ path: `/cloth/detail/${this.info.id}` });
      // }
      // if (this.info.department_id === 2) {
      //   this.$router.push({ path: `/spin/detail/${this.info.id}` });
      // }
    },
  },
};
</script>

<style lang="scss" module>
.listItem {
  width: 145px;
  background-color: #212121;
  border-radius: 12px;
  padding-bottom: 10px;
  .listImg {
    border-radius: 12px;
    width: 145px;
    height: 145px;
  }
  .name {
    padding: 0 7px;
    margin: 10px 0;
    height: 15px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    line-height: 15px;
    @include ellipsis(1);
  }
  .ddc_no {
    background-image: url("~@/assets/img/ddc_no.png");
    background-size: 100% 100%;
    width: 67px;
    height: 22px;
    box-sizing: border-box;
    padding: 4px 0 0 4px;
    margin-left: 5px;
    font-size: 12px;
    color: #000000;
    line-height: 17px;
    text-align: center;
  }
  .author {
    padding: 0 7px;
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #8e8e95;
    .avatar {
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
    .authorName {
      margin-left: 6px;
      @include ellipsis(1);
    }
  }
}
</style>
