<template>
  <div :class="$style.wrap">
    <div :class="$style.top">
      <img v-if="login" :class="$style.avatar" :src="info.headimg" />
      <img v-else :class="$style.avatar" src="@/assets/img/avatar.png" />
      <p v-if="!login" :class="$style.noLogin" @click="handleLogin">点击登录</p>
      <div v-else>
        <div :class="$style.info">
          <span>{{ info.nickname }}</span>
          <span :class="$style.level">LV{{ info.level }}</span>
        </div>
        <p :class="$style.id">ID：{{ desensitize(info.mobile) }}</p>
      </div>
      <div :class="$style.actionWrap">
        <div :class="$style.action">
          <img
            :class="$style.icon"
            src="@/assets/img/me-order.png"
            @click="handle2Order"
          />
          <span>我的订单</span>
        </div>
        <!-- <van-badge :dot="info.wait_confirm > 0">
          <div :class="$style.action">
            <img
              :class="$style.icon"
              src="@/assets/img/me-give.png"
              @click="handle2Give"
            />
            <span>转赠列表</span>
          </div>
        </van-badge> -->

        <div :class="$style.action">
          <img
            :class="$style.icon"
            src="@/assets/img/me-settings.png"
            @click="handle2Settings"
          />
          <span>设置</span>
        </div>
      </div>
    </div>
    <div :class="$style.bottom">
      <van-tabs
        v-model="active"
        background="#000"
        color="#fff"
        title-inactive-color="#8E8E95"
        title-active-color="#fff"
      >
        <van-tab :title="`我的藏品（${collect.total}）`">
          <van-list
            v-if="login"
            :class="$style.collectList"
            v-model="collect.loading"
            :finished="collect.finished"
            :error.sync="collect.error"
            error-text="请求失败，点击重新加载"
            finished-text="没有更多了"
            @load="onLoadCollect"
          >
            <OptimizationCard
              isCollect
              :class="$style.listItem"
              v-for="item in collect.list"
              :key="item.id"
              :info="item"
            />
          </van-list>
        </van-tab>
        <van-tab :title="`关注艺术家（${attention.total}）`">
          <van-list
            v-if="login"
            :class="$style.attentionList"
            v-model="attention.loading"
            :finished="attention.finished"
            :error.sync="attention.error"
            error-text="请求失败，点击重新加载"
            finished-text="没有更多了"
            @load="onLoadAttention"
          >
            <ArtistCard
              mode="fill"
              :class="$style.listItem"
              v-for="(item, index) in attention.list"
              :key="index"
              :info="item"
            />
          </van-list>
        </van-tab>
      </van-tabs>
    </div>
    <!-- <Tab active="personal" :dot="info.wait_confirm > 0" /> -->
    <Tab active="personal" />
    <van-dialog v-model="showGiveTip" title="" :show-confirm-button="false">
      <p :class="$style.iconWrap">
        <van-icon name="cross" @click="showGiveTip = false" />
      </p>
      <p :class="$style.content">您有待接受的藏品礼物！</p>
      <div class="btnWrap">
        <span class="sure" @click="handle2Sure"
          ><span class="sure-text">前往领取</span></span
        >
        <span class="cancel" @click="showGiveTip = false">稍后领取</span>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Tab from "@/components/Tab";
import OptimizationCard from "@/components/OptimizationCard";
import ArtistCard from "@/components/ArtistCard";
import { fetchUserInfo, fetchFollowList, fetchCollectList } from "@/api";
import { getStorage, setStorage } from "@/utils/util";
export default {
  components: {
    Tab,
    OptimizationCard,
    ArtistCard,
  },
  data() {
    return {
      login: true,
      active: 0, // 0我的藏品，1关注的艺术家
      collect: {
        list: [],
        loading: false,
        finished: false,
        error: false,
        pageSize: 15,
        page: 1,
        total: 0,
      }, // 我的藏品
      attention: {
        list: [],
        loading: false,
        finished: false,
        error: false,
        pageSize: 15,
        page: 1,
        total: 0,
      }, // 关注艺术家
      product_number: 0, // 藏品数量
      follow_number: 0, // 关注艺术家数量
      info: {},
      showGiveTip: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const login = getStorage("token");
      this.login = login;
      if (this.login) {
        fetchUserInfo().then((res) => {
          this.info = res;
          setStorage("user_info", res);
          // if (this.info.wait_confirm > 0) {
          //   this.showGiveTip = true;
          // }
        });
        this.getFollowTotal();
      }
    },
    handle2Sure() {
      this.$router.push({
        path: "/give",
        query: {
          tab: 1,
        },
      });
    },
    handleLogin() {
      this.$router.push("/login");
    },
    getCollectList() {
      const data = {
        page_size: this.collect.pageSize,
        page: this.collect.page,
      };
      fetchCollectList(data)
        .then((res) => {
          const { list, total, page } = res;
          this.collect.total = total;
          this.collect.list = [...this.collect.list, ...list];
          this.collect.loading = false;
          this.collect.page = page + 1;
          if (this.collect.list.length >= this.collect.total) {
            this.collect.finished = true;
          }
        })
        .catch(() => {
          this.collect.error = true;
          this.collect.loading = false;
        });
    },
    onLoadCollect() {
      this.getCollectList();
    },
    // 获取关注的艺术家列表
    getAttentionList() {
      const data = {
        page_size: this.attention.pageSize,
        page: this.attention.page,
      };
      fetchFollowList(data)
        .then((res) => {
          const { list, total, page } = res;
          this.attention.total = total;
          this.attention.list = [...this.attention.list, ...list];
          this.attention.loading = false;
          this.attention.page = page + 1;
          if (this.attention.list.length >= this.attention.total) {
            this.attention.finished = true;
          }
        })
        .catch(() => {
          this.attention.error = true;
          this.attention.loading = false;
        });
    },
    onLoadAttention() {
      this.getAttentionList();
    },
    // 获取关注艺术家的数量
    getFollowTotal() {
      fetchFollowList({ page: 1, page_size: 15 }).then((res) => {
        const { total } = res;
        this.attention.total = total;
      });
    },
    handle2Order() {
      if (!this.login) {
        this.$toast("未登录,请先去登录");
        return;
      }
      this.$router.push({ path: "/order" });
    },
    handle2Give() {
      if (!this.login) {
        this.$toast("未登录,请先去登录");
        return;
      }
      this.$router.push({ path: "/give" });
    },
    handle2Settings() {
      if (!this.login) {
        this.$toast("未登录,请先去登录");
        return;
      }
      this.$router.push({ path: "/settings" });
    },
    desensitize(mobile) {
      return mobile
        ? mobile.substring(0, 3) + "****" + mobile.substring(7)
        : "";
    },
  },
};
</script>

<style lang="scss" module>
.wrap {
  .top {
    text-align: center;
    height: 323px;
    .avatar {
      width: 97px;
      height: 97px;
      border-radius: 50%;
      margin-top: 50px;
    }
    .noLogin {
      height: 28px;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      margin: 17px 0 49px;
    }
    .info {
      margin: 8px 0 4px;
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      .level {
        text-align: center;
        display: inline-block;
        width: 41px;
        height: 19px;
        line-height: 19px;
        background: linear-gradient(223deg, #5ffcff 0%, #eeadef 100%);
        border-radius: 20px;
        font-size: 12px;
        font-weight: 500;
        color: #000000;
        margin-left: 5px;
      }
    }
    .id {
      font-size: 16px;
      color: #ababab;
      line-height: 22px;
      letter-spacing: 1px;
      margin-bottom: 32px;
    }
    .actionWrap {
      display: flex;
      justify-content: space-between;
      .action {
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 12px;
        color: #d3d3d3;
        line-height: 17px;
        &:nth-child(2) {
          position: relative;
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            height: 29px;
            width: 1px;
            background-color: #4b5055;
            content: "";
          }
          /* &::after {
            position: absolute;
            top: 0;
            right: 0;
            height: 29px;
            width: 1px;
            background-color: #4b5055;
            content: "";
          } */
        }
        .icon {
          width: 29px;
          height: 29px;
          margin-bottom: 8px;
        }
      }
    }
  }
  .bottom {
    min-height: calc(100vh - 323px);
    background-color: #000;
    .collectList {
      padding: 18px 27px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      :global {
        .van-list__loading,
        .van-list__finished-text,
        .van-list__error-text {
          width: 100%;
        }
      }
      .listItem {
        margin-bottom: 21px;
      }
    }
    .attentionList {
      padding: 20px 32px;
      .listItem {
        margin-bottom: 16px;
      }
    }
  }

  .iconWrap {
    padding: 12px 10px 0 0;
    text-align: right;
  }
  .content {
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    text-align: center;
    margin: 14px 0 24px;
    text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.5);
  }
}
</style>
