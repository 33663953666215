// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/ddc_no.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".OptimizationCard_listItem_3iFOg{background-color:#212121;padding-bottom:.26667rem}.OptimizationCard_listItem_3iFOg,.OptimizationCard_listItem_3iFOg .OptimizationCard_listImg_2IyK5{width:3.86667rem;border-radius:.32rem}.OptimizationCard_listItem_3iFOg .OptimizationCard_listImg_2IyK5{height:3.86667rem}.OptimizationCard_listItem_3iFOg .OptimizationCard_name_3gN4e{padding:0 .18667rem;margin:.26667rem 0;height:.4rem;font-size:.32rem;font-weight:500;color:#fff;line-height:.4rem;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1;word-break:break-all}.OptimizationCard_listItem_3iFOg .OptimizationCard_ddc_no_1uuGf{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;width:1.78667rem;height:.58667rem;box-sizing:border-box;padding:.10667rem 0 0 .10667rem;margin-left:.13333rem;font-size:.32rem;color:#000;line-height:.45333rem;text-align:center}.OptimizationCard_listItem_3iFOg .OptimizationCard_author_1i8TI{padding:0 .18667rem;display:flex;align-items:center;font-size:.26667rem;color:#8e8e95}.OptimizationCard_listItem_3iFOg .OptimizationCard_author_1i8TI .OptimizationCard_avatar_19h1o{width:.42667rem;height:.42667rem;border-radius:50%}.OptimizationCard_listItem_3iFOg .OptimizationCard_author_1i8TI .OptimizationCard_authorName_22jBo{margin-left:.16rem;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:1;word-break:break-all}", ""]);
// Exports
exports.locals = {
	"listItem": "OptimizationCard_listItem_3iFOg",
	"listImg": "OptimizationCard_listImg_2IyK5",
	"name": "OptimizationCard_name_3gN4e",
	"ddc_no": "OptimizationCard_ddc_no_1uuGf",
	"author": "OptimizationCard_author_1i8TI",
	"avatar": "OptimizationCard_avatar_19h1o",
	"authorName": "OptimizationCard_authorName_22jBo"
};
module.exports = exports;
